// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-email-change-js": () => import("./../../../src/pages/email-change.js" /* webpackChunkName: "component---src-pages-email-change-js" */),
  "component---src-pages-gallery-bathrooms-js": () => import("./../../../src/pages/gallery/bathrooms.js" /* webpackChunkName: "component---src-pages-gallery-bathrooms-js" */),
  "component---src-pages-gallery-exterior-js": () => import("./../../../src/pages/gallery/exterior.js" /* webpackChunkName: "component---src-pages-gallery-exterior-js" */),
  "component---src-pages-gallery-game-rooms-js": () => import("./../../../src/pages/gallery/game-rooms.js" /* webpackChunkName: "component---src-pages-gallery-game-rooms-js" */),
  "component---src-pages-gallery-interior-js": () => import("./../../../src/pages/gallery/interior.js" /* webpackChunkName: "component---src-pages-gallery-interior-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gallery-kitchens-js": () => import("./../../../src/pages/gallery/kitchens.js" /* webpackChunkName: "component---src-pages-gallery-kitchens-js" */),
  "component---src-pages-gallery-living-areas-js": () => import("./../../../src/pages/gallery/living-areas.js" /* webpackChunkName: "component---src-pages-gallery-living-areas-js" */),
  "component---src-pages-gallery-outdoor-areas-js": () => import("./../../../src/pages/gallery/outdoor-areas.js" /* webpackChunkName: "component---src-pages-gallery-outdoor-areas-js" */),
  "component---src-pages-gallery-specialty-rooms-js": () => import("./../../../src/pages/gallery/specialty-rooms.js" /* webpackChunkName: "component---src-pages-gallery-specialty-rooms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitation-js": () => import("./../../../src/pages/invitation.js" /* webpackChunkName: "component---src-pages-invitation-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/meet-the-team.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-values-js": () => import("./../../../src/pages/our-values.js" /* webpackChunkName: "component---src-pages-our-values-js" */),
  "component---src-pages-recovery-js": () => import("./../../../src/pages/recovery.js" /* webpackChunkName: "component---src-pages-recovery-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-video-list-template-js": () => import("./../../../src/templates/video-list-template.js" /* webpackChunkName: "component---src-templates-video-list-template-js" */)
}

